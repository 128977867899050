.rpb-timeline {
  width: 90%;
  max-width:1600px;
  margin: 0 auto;
  &:after {
    :after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

/* --------------------------------

Main components

-------------------------------- */


.rpb-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
.rpb-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 25px;
  height: 100%;
  width: 1px;
  background: #bdbdbd;
}
@media only screen and (min-width: 1170px) {
  .rpb-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .rpb-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.timeline-box-container {
  position: relative;
  margin: 2em 0;
}
.timeline-box-container:after {
  content: "";
  display: table;
  clear: both;
}
.timeline-box-container:first-child {
  margin-top: 0;
}
.timeline-box-container:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .timeline-box-container {
    margin: 4em 0;
  }
  .timeline-box-container:first-child {
    margin-top: 0;
  }
  .timeline-box-container:last-child {
    margin-bottom: 0;
  }
}

.rpb-timeline-img {
  position: absolute;
  top: 8px;
  left: 21px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 8px #f7f7f7;
}
.rpb-timeline-img {
  background: #bdbdbd;
}
@media only screen and (min-width: 1170px) {
  .rpb-timeline-img {
    width: 9px;
    height: 9px;
    left: 50%;
    margin-left: -6px;
    margin-top: 15px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
}

.timeline-box-inner {
  position: relative;
  margin-left: 60px;
  margin-right: 30px;
  box-shadow:0px 1px 22px 4px rgba(0, 0, 0, 0.07);
  .timeline-header {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 400;
    background:$brand-primary;
    .number-box {
      display: inline;
      float: left;
      padding: 10px;
      font-size: 35px;
      line-height: 35px;
      font-weight: 600;
      background: rgba(0, 0, 0, 0.17);
    }
    .h4 {
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: inherit;
      letter-spacing: 2px;
      margin: 0;
      padding-bottom: 6px;
      font-weight:bold;
      line-height: 1rem;
      @include media-breakpoint-up(sm) {
        font-size: 165%;
        line-height: 1.2rem;
      }
      span {
        display: block;
        font-size: 0.6rem;
        margin: 0;
        font-weight:normal;
        @include media-breakpoint-up(sm) {
          font-size: 0.8rem;
        }
      }
    }
  }
  .timeline-content {
    background:white;
    font-size: 14px;
    line-height: 24px;
    padding:1rem;
  }
}
.timeline-box-inner:after {
  content: "";
  display: table;
  clear: both;
}

.timeline-box-inner p, .timeline-box-inner .timeline-date {
  font-size: 13px;
  font-size: 0.8125rem;
}
.timeline-box-inner .timeline-date {
  display: inline-block;
  font-weight:bold;
  @include media-breakpoint-down(xl) {
    float:right;
    padding:0.5rem 1rem;
  }
}
.timeline-box-inner p {
  margin-bottom:0;
}

.timeline-box-inner::before {
  content: '';
  position: absolute;
  top: 7px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid $brand-primary;
}

@media only screen and (min-width: 768px) {
  .timeline-box-inner h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .timeline-box-inner p {
    font-size: 16px;
    font-size: 1rem;
  }
  .timeline-box-inner .cd-read-more, .timeline-box-inner .timeline-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@include media-breakpoint-up(xl) {
  .timeline-box-inner {
    margin-left: 0;
    width: 36%;
    margin: 0 5%
  }
  .timeline-box-inner::before {
    top: 18px;
    left: 100%;
    border-color: transparent;
    border-left-color: $brand-primary;
  }
  .timeline-box-inner .timeline-date {
    position: absolute;
    width: 100%;
    left: 100%;
    top: -50px;
    font-size: 16px;
    font-size: 1rem;
  }
  .timeline-box-container:nth-child(even) .timeline-box-inner {
    float: right;
  }
  .timeline-box-container:nth-child(even) .timeline-box-inner::before {
    top: 18px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: $brand-primary;
  }
  .timeline-box-container:nth-child(even) .timeline-box-inner .cd-read-more {
    float: right;
  }
  .timeline-box-container:nth-child(even) .timeline-box-inner .timeline-date {
    left: auto;
    right: 110%;
    text-align: right;
  }
}
