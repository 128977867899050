.contact-page {
  // map
  .single-location-map {
    height:350px;
    @include media-breakpoint-up(lg) {
      min-height:350px;
      height:100%;
    }
    iframe {
      width:100%;
      height:350px;
      @include media-breakpoint-up(lg) {
        height:100%;
        min-height:350px;
      }
    }
  }
}