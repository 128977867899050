.hero-style-1 {
	.hero-main-col {
		.hero-headline {
			font-size:24px;
			text-shadow: 1px 1px 0 #303030;
			font-weight:700;
			font-family:$heading-font;
			@include media-breakpoint-up(lg) {
				font-size:55px;
			}
		}

		.hero-subline {
			font-size:18px;
			text-shadow: 1px 1px 0 #303030;
			font-family:$heading-font;
			@include media-breakpoint-up(lg) {
				font-size:36px;
			}
		}

		.hero-button {
			margin-top:45px;
		}
	}
}
