.top-popup {
  z-index:1060!important;
  .top-popup-image {
    .btn-close {
      color:white;
      background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
      padding:0;
      position:absolute;
      top:3px;
      right:-35px;
      opacity:1;
      z-index:100;
    }
  }
  .modal-body {
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
  }
}

.location-slider-modal {
  .btn-close {
    color:white;
    background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    opacity:1;
    position:absolute;
    top: 18px;
    right: 20px;
    z-index: 100;
    opacity: 0.85;
  }
  .modal-dialog {
    .modal-content {
      border-radius: 23px;
      border: 1px solid #fff;
      background-color:rgba(255,255,255,0.25);
      -webkit-backdrop-filter: saturate(180%) blur(16px);
      backdrop-filter: saturate(180%) blur(16px);
      padding-bottom: 1.25rem;
      p {
       color: #191919;
      }
      .modal-body {
        .splide__slider {
          .rpb-slider-arrows {
            .splide__arrow {
              background: rgba(255, 255, 255, 0.1);
              opacity: 1;
              box-shadow: none !important;
              border: 1px solid #fff;
            }
            .splide__arrow--prev {
              left:0;
            }
            .splide__arrow--next {
              right:0;
            }
          }
          .splide__track {
            margin-left:3rem;
            margin-right:3rem;
          }
        }
        .location-popup-image {
          background-size:cover;
          background-position:center;
          min-height:50px;
          border-radius: 14px 14px 0 0;
          @include media-breakpoint-up(lg) {
            min-height:150px;
          }
        }
        .location-content {
          border-radius: 0 0 14px 14px;
        }
        .location-content-no-image {
          border-radius:14px;
        }
        .splide__pagination {
          display:none!important;
        }
      }
    }
  }
}