.service-area-search-box {
  p {
    &:last-of-type {
      margin-bottom:1rem; // override most template sections that strip this margin
    }
  }
  .sas-content {
    .sas-content-step {
      margin-bottom:1rem;
    }
    .sas-hidden-step {
      display:none;
    }
    .sas-location-row {
      display:none;
    }
  }
  .zip-code-form {
    input[type="text"],input[type="number"] {
      height:50px;
    }
    .zipcode-error {
      color:red;
      font-weight:bold;
      text-align:center;
    }
  }
}