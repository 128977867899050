.woocommerce {
  .basebuild-shop-single {
    background:transparent;
    position:relative;

    .product {
      display:flex;
      flex-wrap:wrap;
      .woocommerce-product-gallery {
        background:white;
        display:flex;
        align-self:flex-start;
        justify-content:center;
      }

      .onsale {
        left:auto;
        right:0;
        top:0;
        right:auto;
        margin:0;
        background-color:$brand-primary;
        border-radius:0;
        min-width:auto;
        min-height:auto;
        padding:10px;
        line-height:1;
      }

      .summary {
        margin-top:0;

        @include media-breakpoint-up(lg) {
          padding-left:2rem;
        }

        .product_title {
          color: $dark;
          margin-bottom: 1.5rem;
        }

        .woocommerce-product-details__short-description {
          margin-bottom:2rem;
        }

        ul.wcsatt-options-prompt-radios {
          padding:0;
        }
        .woocommerce-Price-amount {
          color:$dark;
        }
        .price {
          font-weight:$medium-weight;
          color:$dark;
          float: left;
          padding-top: 0.75rem;
          padding-right: 3rem;
          line-height: 1;
          ins {
            text-decoration:none;
          }
        }
        .cart {
          display:flex;
          align-items:center;
          @include media-breakpoint-down(xl) {
            width:100%;
          }
          button {
            border:0;
            transition:all 350ms ease-in-out;
          }
          .quantity {
            margin-right:1rem;
            padding:0.5rem 1.5rem;
          }
        }
        .single-product-sku {
          font-weight:$normal-weight;
          font-size:1rem;
          color:$inactive;
          .sku-title {
            color:$dark;
          }
        }
      }

      .woocommerce-tabs {
        margin-top:4rem;
        ul.tabs {
          margin:0;
          padding:0;
          margin-bottom:2rem;
          &:before {
            display:none;
          }
          li.active {
            &:before, &:after {
              display:none;
            }
            background:transparent;
            border:0;
            border-bottom:2px solid $dark;
            font-weight:$medium-weight;
            border-radius:0;
            a {
              font-weight:$medium-weight;
            }
          }
          li {
            &:before, &:after {
              display:none;
            }
            color:$dark;
            border:0;
            border-radius:0;
            font-weight:$normal-weight;
            background:transparent;
            background-color:transparent;
            padding-left: 2rem;
            padding-right: 2rem;
            a {
              font-weight:$normal-weight;
              color:$dark;
            }
          }
        }
        .comment-form {
          background:white;
          padding:3rem;
          display:none;
        }
      }

      .variations {
        display:flex;
        label {
          font-size:20px;
          font-weight:600;
          line-height:40px;
        }
        .wc-default-select {
          font-size:20px;
          height:40px;
          border-radius:0;
          border:1px solid rgb(59,59,59);
          padding-left:15px;
          &:focus-within, &:focus {
            border-radius:0;
            border:1px solid rgb(59,59,59);
            outline:none;
          }
        }
        td.label {
        }
        label {
          font-weight:600!important;
        }
      }
      .single_variation_wrap {
        .price {
          display:inline-block;
          margin-bottom:30px;
          padding:0;
          background:transparent;
          font-size:20px;
          color:$dark;
        }
        .woocommerce-Price-amount {
          color:$dark;
        }
      }
    } // end .product

    .thumbnails img {
      width: auto !important;
      margin: 0 auto;
      max-height: 100px;
    }

    .product_meta {
      display:none;
    }
    .related {
      .basebuild-products-container {
        .products {
          li.product {
            @include media-breakpoint-up(md) {
              flex-basis: 50%;
            }
            @include media-breakpoint-up(xxl) {
              flex-basis:25%;
            }
          }
        }
      }
    }
  }
}

.single-product-main-image {
  img {
    max-width:100%;
    width:auto !important;
    margin:0 auto;
    max-height:400px;
  }
}

.woocommerce #content div.product div.images, .woocommerce div.product div.images, .woocommerce-page #content div.product div.images, .woocommerce-page div.product div.images {
  flex-basis:100%;
  @include media-breakpoint-up(lg) {
    flex-basis:40%;
  }
}

.woocommerce #content div.product div.summary, .woocommerce div.product div.summary, .woocommerce-page #content div.product div.summary, .woocommerce-page div.product div.summary {
  flex-basis:100%;
  @include media-breakpoint-up(lg) {
    flex-basis:60%;
  }
}

.woocommerce-Reviews {
  .commentlist {
    padding:0;
    li {
      .comment-text {
        margin:0!important;
        border:0!important;
        padding:0!important;
        .woocommerce-review__author {
          color:$dark;
        }
      }
    }
  }
}
