.woocommerce-checkout {
	.checkout-coupons {
		.woocommerce-info {
			margin-bottom:0 !important;
			border-top:none;
			background:transparent;
			padding:0;
			margin:0;
			&:before {
				display:none;
			}
		}

		.checkout_coupon {
			width:100%;
			border:0;
			padding:0;
			margin:0 auto;
			margin-top:1rem;
			@include media-breakpoint-up(lg) {
				width:39%;
				min-width:470px;
			}
			.coupon-form-row {
				position:relative;
				.input-text {
					height:49px;
					padding:13px 90px 10px 20px;
					width:100%;
					border:1px solid $medium;
					&:focus-within, &:focus, &:active {
						border:1px solid $medium;
						outline:0;
					}
				}
				.button {
					background:none!important;
					height:50px;
					position:absolute;
					right:0;
					top:0;
					width:auto!important;
					padding:10px 20px !important;
					color:$dark;
					i,svg {
						color:$dark;
					}
				}
			}
			border-top:0;
		}
	}

	.basebuild-checkout {
		margin-top:30px;
		margin-bottom:2.5rem;
		input, .select2-container--default .select2-selection--single, textarea {
			height:2.5rem;
			padding:0.5rem;
			border:1px solid $medium;
			border-radius:0;
		}

		textarea {
			height:4em;
		}

		.select2-container--default .select2-selection--single .select2-selection__arrow {
			height:2.5rem;
		}

		.select2-container--default .select2-selection--single .select2-selection__rendered {
			line-height:normal;
			padding-left:0;
		}

		.input-checkbox {
			height:auto;
		}

		.woocommerce-additional-fields {
			.woocommerce-input-wrapper {
				width:100%;
			}
		}
		.basebuild-checkout-row {
			.basebuild-checkout-details {
				.h4 {
					text-transform:uppercase;
					margin-bottom: 30px;
				}
				.woocommerce-billing-fields__field-wrapper, .woocommerce-shipping-fields, .woocommerce-additional-fields {
					.form-row-first,.form-row-last {
						width:50%;
						.woocommerce-input-wrapper {
							width:100%;
						}
					}
					.form-row {
						margin-bottom:1rem;
					}
					.form-row label {
						width:100%;
						line-height: 2;
						text-transform: uppercase;
				    font-weight: $medium-weight;
				    font-size: 0.75rem;
					}
					.form-control {
						border:1px solid #d2d2d2;
						border-radius:2px;
					}
					.woocommerce-input-wrapper {
						width:100%;
					}
					#billing_address_1_field {
						margin-bottom:5px;
					}
				}
				#ship-to-different-address {
					text-transform:none;
					margin-top:1rem;
					padding-bottom:0;
					margin-bottom:1rem;
					font-size:1rem;
					border:none;
				}
				.woocommerce-form__label-for-checkbox span {
					padding-left:10px;
				}
				.shipping_address {
					display:none;
				}
			}
		}
		.basebuild-checkout-review {
			.woocommerce-checkout-review-order {
				background:white;
			}
			.h4 {
				text-transform:uppercase;
				margin-bottom: 30px;
			}
			.shop_table {
				border-radius:0;
				border:0;
				border-collapse:collapse;
				font-size:1rem;
				thead {
					background:transparent;
					border:0;
					font-weight:$medium-weight;
					text-transform:uppercase;
				}
				.product-name {
					width:55%;
				}

				.product-total {
					text-align:right;
				}

				.cart-subtotal, .order-total, .shipping {
					th {
						width:55%;
						text-transform:uppercase;
					}
					th,td {
					}
				}

				.cart-subtotal {
					th, td {
						padding-top:3rem;
					}
				}
			}
			.cart_item, .cart-subtotal, .shipping {
				color:$dark;
			}
			.order-total {
				color:$dark;
			}
			#payment {
				background:transparent;
			}
			.place-order {
				.button {
					padding:20px 15px;
				}
			}
			// stripe
			.payment_method_stripe {
				.form-row {
					margin-bottom:0 !important;
					padding:0;
				}
				label[for=payment_method_stripe] {
					font-weight:700;
					text-transform:uppercase;
				}
				label[for=stripe-card-element] {
					width:100%;
					line-height:1;
				}
				.stripe-card-group {
					width:100%;
				}
				label[for=stripe-exp-element] {
					width:100%;
					line-height:1;
				}
				.wc-stripe-elements-field {
					width:100%;
				}
				label[for=stripe-cvc-element] {
					width:100%;
					line-height:1;
				}
				.wc-stripe-elements-field {
					height: 40px;
					padding-top: 11px;
					border:1px solid #d2d2d2;
				}
			}
		}
		.woocommerce-privacy-policy-text {
			p {
				font-size:12px;
				line-height:1;
				text-align:center;
			}
		}
	}
}

.woocommerce table.shop_table tbody th, .woocommerce table.shop_table tfoot td, .woocommerce table.shop_table tfoot th {
	border-top:0;
}
