// Page header
.woocommerce-products-header {
  background-size: cover;
  background-position: center center;
  padding-top: 20px;
  padding-bottom: 20px;
  @include media-breakpoint-up(lg) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .products-header-title {
    font-size: 2.5rem;
    line-height: 1.2;
    color: white;
    font-family: $heading-font;
    padding: 0;
    margin: 0;
  }
}

// Notices Wrapper
.woocommerce-shop {
  .woocommerce-notices-wrapper {
    padding-left:0.75rem;
    padding-right:0.75rem;
    margin-left:auto;
    margin-right:auto;
    max-width:100%;

    @include media-breakpoint-up(md) {
      padding-left:30px;
      padding-right:30px;
    }
    @include media-breakpoint-up(lg) {
      padding-left:80px;
      padding-right:80px;
    }
    @media screen and (min-width: 1800px) {
      max-width: unset;
      padding-left: 190px;
      padding-right: 190px;
    }
  }
}

// Main Shop
.basebuild-order-results {
  margin-bottom: 20px;
}
.basebuild-shop-main {
  padding-top: 60px;
  padding-bottom: 60px;
  background:$light;
  .basebuild-shop-filter {
    .basebuild-filter-title {
      background: $brand-primary;
      padding: 15px;
      color: white;
      p, .h3 {
        padding: 0;
        margin: 0;
        color: white;
      }
    }
    .basebuild-shop-filter-in {
      background:white;
      display:none;
      @include media-breakpoint-up(xl) {
        background:transparent;
        display:block!important;
      }

      ul {
        display: block;
        padding: 0;
        list-style:none;
        .cat-item {
          a {
            font-weight:$normal-weight;
            color:$dark;
            display:block;
            padding-bottom:0.75rem;
          }
        }
        .children {
          margin-left:1rem;
        }
      }
      .chosen-container-single .chosen-single {
        background: white;
        border-radius: 1px;
        border: 0;
      }
    }
    .woocommerce-sidebar {
      .widget {
        margin-bottom:3rem;
        .h4 {
          margin-bottom:1.5rem;
        }
      }
    }
  } // end filter
  // Product Loop Styles
  .woocommerce ul.products li.product .woocommerce-loop-product__title {
    text-align: left;
  }
  .woocommerce .products .star-rating {
    margin: 0 auto;
    margin-top: 10px;
    &:hover {
      color: $brand-secondary;
    }
  }
  .woocommerce ul.products li.product .price {
    text-align: left;
  }
} // end .basebuild-shop-main

// a few shop page globals
.woocommerce ul.products li.product a img {
  margin: 0 auto;
}
.woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
  text-align: left;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
  background-color:$brand-primary;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
  background-color:$brand-primary;
}

// Loop (mainly used on shop page but also called in places like related products on single product)
.woocommerce {
  .basebuild-products-container {
    .products {
      display: flex;
      flex-wrap: wrap;
      li.product {
        width: auto;
        margin: 0;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        flex-basis:100%;
        flex-wrap:nowrap;
        @include media-breakpoint-up(md) {
          flex-basis: 50%;
        }
        @include media-breakpoint-up(xxl) {
          flex-basis:33.33%;
        }
        .basebuild-add-to-cart-loop {
          margin-top: auto;
        }
        li.product:hover {
          .star-rating {
            color: $brand-primary;
          }
        }
        .basebuild-loop-item {
          margin-left:1rem;
          margin-right:1rem;
          background:white;
          position:relative;
          .onsale {
            left:auto;
            right:0;
            margin:0;
            background-color:$brand-primary;
            border-radius:0;
            min-width:auto;
            min-height:auto;
            padding:10px;
            line-height:1;
          }
          .wishlist {
            position:absolute;
            top:1rem;
            right:1.5rem;
          }
          .basebuild-loop-content {
            padding:1rem 1.5rem;
            position:relative;
            a {
              color:$dark;
            }
            .basebuild-loop-cats {
              a {
                font-size:0.75rem;
                font-weight:$normal-weight;
              }
            }
            .basebuild-add-to-cart-loop {
              text-align: left;
              position:absolute;
              bottom:1rem;
              right:1.5rem;
              a {
                color:white;
              }
            }

            ins {
              text-decoration:none;
            }

            del {
              display:none;
            }
          }
        }
      }
    }
  }
}
