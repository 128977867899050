.age-gate-modal-backdrop-image {
  background-size: cover !important;
  background-position: center center !important;
}

.modal-background-video {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}

.age-gate-modal-backdrop-video.show {
  opacity:1!important;
}

.age-gate-modal {
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index:999999;
  .modal-content {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.92);
  }

  .age-gate-modal-logo {
    text-align: center;
    img {
      max-width: 65%;
      height:auto;
    }
  }

  .h5 {
    font-weight: bold;
  }

  .modal-header {
    border: 0;
  }

  .modal-footer {
    border: 0;
    font-family: $paragraph-font;
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
    font-family: $paragraph-font;
  }

  .age-gate-denied-body {
    display: none;
  }
}
