.navbar {
  .navbar-collapse {
    flex-wrap:wrap;
  }
}

.header-topbar {
  line-height: 20px;
  font-family: $subheading-font;
  cursor: default !important;
  letter-spacing: 0.5px;
  font-size: 15px;
  transition:all 350ms linear;
  p {
    display:inline;
    margin:0;
    margin-bottom:0.5rem;
    &:last-of-type {
      margin-bottom:0;
    }
    @include media-breakpoint-up(lg) {
      margin:0;
      margin-bottom:0;
    }
  }
  @include media-breakpoint-down(xs) {
    font-size: 16px !important;
  }
  .nav-emergency {
    display: none;
    color: white;
    padding-right: 10px;
    font-size: 18px;
    font-weight: normal !important;
    text-transform: uppercase !important;
    @include media-breakpoint-up(lg) {
      display: inline-block;
    }
  }
  .primary-btn {
    background: $brand-primary-offset;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.05em;
    margin-right: 10px;
    text-shadow: none;
    box-shadow: none;
    border: 0px;
    padding: 5px 10px 5px 10px;
    text-transform: none;
    &:after {
      display: none;
    }
  }
  .social-media-link {
    color: #fff;
    padding-right: 10px;
    position: relative;
    display: inline-block;
    margin-top: 0px;
    &:hover {
      color: #fff;
      opacity: 0.8;
    }
  }
  .phone {
    display: inline-block;
    font-size: 15px;
    margin-left: 15px;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
    .sub-heading {
      color: $accent;
      margin: 0px;
      letter-spacing: normal;
      font-size: 12px;
      display: block;
    }
  }
  // Topbar Styles
  &.topbar-attached {
    flex-basis:100%;
  }
  // Hide on scroll
  &.topbar-hide {
    &.topbar-scrolling {
      display:none;
    }
  }
}