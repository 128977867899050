.woocommerce {
  .basebuild-cart-row {
    .basebuild-cart-table {
      font-weight:$medium-weight;
      a {
        color:$dark;
      }
      .basebuild-cart-thumbnail {
        a {
          img {
            width:auto;
            max-height:150px;
          }
        }
      }
    }
  }

  .basebuild-cart-totals {
    background:white;
    .cart-collaterals {
      .cart_totals {
        float:none;
        width:100%;

        .table {
          font-size:1rem;
        }

        .table > :not(caption) > * > * {
          padding-bottom:1.5rem;
        }

        th {
          text-transform:uppercase;
        }

        .order-total {
          th, td {
            padding-top:1.5rem;
            border-top:1px solid $medium !important;
          }
          .amount {
            font-size:1.6rem;
          }
        }

        tbody {
          border:0!important
        }
        .cart-totals-heading {
          padding-bottom:1rem;
          border-bottom:1px solid $medium;
        }
        a.checkout-button {
          margin-bottom:0!important;
        }
      }
    }
  }

  .basebuild-cart-continue {
    .btn {
      padding:20px 15px;
      width:100%;
    }
  }
}
