.rpb-simple-content-half-width {
  .hc-half-content {
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
  }
  .hc-half-content-image {
    min-height:250px;
  }
}

.rpb-simple-content-full-width {
  .hc-content {
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
  }
}