.mso-map-container {
  @include media-breakpoint-down(xl) {
    padding:0!important;
  }
  .mso-map {
    position:relative;
    transition:all 150ms;
    display:flex;
    flex-direction:column;
    @include media-breakpoint-up(xl) {
      border-radius: 20px;
    }
    .gm-style-pbc {
      opacity: 0.015 !important;
    }
    .mso-iframe {
      // box-shadow: -11px 15px 16px -10px rgb(0 0 0 / 15%);
      min-height: 300px;
      width: 100%;
      order:1;
      @include media-breakpoint-up(xl) {
        border-radius: 20px;
        order:2;
        min-height: 750px;
      }
    }
  }

  .scrollbar-primary::-webkit-scrollbar {
  width: 8px;
  background-color: $gray; }

  .scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: $brand-primary; }

  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    border-radius: 0!important;
    padding: 0;
    margin: 0;
    .mso-iframe {
      height:100%;
    }
    .store-locator-sidebar-container {
      .store-locator-sidebar {
        .mso-scroll-list {
          max-height:100%;
        }
      }
    }
  }

  .store-locator-search-box {
    .mso-searchbar {
      width:100%;
      border-radius:30px;
      background:white;
      border:0;
    }
  }

  .store-locator-fullscreen-button {
    display:none;
    position:absolute;
    top:0;
    right:0;
    padding-right:inherit;
    z-index:10;
    .msocompress {
      display:none;
    }
    @include media-breakpoint-up(lg) {
      display:block;
    }
    .store-locator-fullscreen-in {
      background: rgba(0,0,0,.12);
      -webkit-backdrop-filter: saturate(180%) blur(8px);
      border-bottom-left-radius:20px;
      border-top-right-radius:20px;
      backdrop-filter: saturate(180%) blur(8px);
      padding-bottom:10px!important;
      transition:all 150ms ease;
      &:hover {
        background:$brand-primary;
        a {
          color:white;
        }
      }
      a {
        color:$brand-primary;
        font-size:25px;
      }
    }
  }

  .store-locator-sidebar-container {
    order:2;
    @include media-breakpoint-up(xl) {
      order:1;
      z-index:10;
      .google_logo {
        display:block;
      }
    }
    .store-locator-sidebar {
      @include media-breakpoint-down(xl) {
        padding-top:45px!important;
      }
      @include media-breakpoint-up(xl) {
        border-radius:20px;
      }
      .mso-searchbar {
        display:flex;
        flex-wrap:wrap;
        @include media-breakpoint-up(xl) {
          flex-wrap:nowrap;
        }
        .store-locator-search-box {
          flex: 0 0 100%;
          display:flex;
          @include media-breakpoint-up(xl) {
            flex: 1 1 60%;
          }
        }
        .mso-search-filter {
          display:flex;
          flex: 0 1 auto;
          @include media-breakpoint-down(xl) {
            flex:0 0 100%;
          }
          .mso-filter-button {
            flex-wrap:nowrap;
          }
        }
      }
      #showAllLoc {
        font-size:14px;
      }
      .all-locations-div {
        margin-bottom:-10px;
      }
      .mso-sidebar-address {
        font-size:16px;
      }
      .mso-sidebar-more {
        font-size:13px;
      }
      .mso-sidebar-full-details {
        font-size:16px;
      }
      .mso-scroll-list {
        padding:0;
        max-height:350px;
        overflow-y:scroll;
        @include scrollbars(0px, $brand-primary, $gray);
        @include media-breakpoint-up(xl) {
          max-height:625px;
          @include scrollbars(5px, $brand-primary, $gray);
          direction: rtl;
        }
        .mso-collapse {
        }
        .loc_item {
          .mso-full-details-button {
            &:hover {
              color:white!important;
            }
          }
          @include media-breakpoint-up(xl) {
            direction:ltr;
          }
        }
      }
    }
  }

  .store-locator-filter-container {
    opacity:0;
    width:100%;
    visibility:hidden;
    transition:all 500ms ease;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    z-index:999999;
    padding-left:inherit;
    background:$brand-primary;
    @include media-breakpoint-up(xl) {
      width:0;
      bottom:25px;
      background: rgb(20,89,186);
      background: linear-gradient(90deg, rgba(20,89,186,0.75) 0%, rgba(20,89,186,1) 49%);
      border-radius:20px;
      border-top-right-radius:0;
    }

    .mso-filter-left {
      width:0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      text-align:center;
      visibility:hidden;
      @include media-breakpoint-up(xl) {
        width:50%;
        visibility:visible;
      }
      &:hover {
        cursor:pointer;
      }
      .mso-filter-back {
        padding-top:100px!important;
        visibility:hidden;
        .mso-filter-back-icon {
          font-size:90px;
          i, svg {
            opacity:0.85;
          }
        }
        .mso-filter-back-text {
          font-size:18px;
          opacity:0.85;
        }
      }
    }
    .mso-filter-right {
      width:100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      @include media-breakpoint-up(xl) {
        width:50%;
      }
    }
    .store-locator-filter {
      .mso-filter {
        background: rgba(255,255,255,.62);
        -webkit-backdrop-filter: saturate(180%) blur(8px);
        border-radius:20px;
        backdrop-filter: saturate(180%) blur(8px);
        color:$dark;
        label {
          font-size:16px;
          font-weight:bold;
          margin-bottom:5px;
          text-align:center;
          width:100%;
        }
        input[type="text"], select {
          border-radius:15px;
        }
      }
    }
  }
}

.pac-container {
  color: #c55a5a;
  border-radius:20px;
  border: none;
  background-color: white;
  padding: 0.75rem;
  margin-top: 5px;
  z-index:99999;
}
textarea:focus,
input:focus {
  outline: none;
}

/*
class for each line of the result
*/
.pac-item {
  font-size: 12px;
  color: #002a77;
  font-family: Arial, Helvetica, sans-serif;
}
.location_name {
  color: #1f67cd;
  font-weight: 600;
  font-size: 1.477rem;
}
.tab.collapsed {
  .location_name {
    font-weight: 400;
    color: black;
  }
}
.row_container {
  padding-right: 8vw;
  @include media-breakpoint-down(lg) {
    padding-right: 0;
  }
}
.left_container {
  margin-right: 1rem;
  @include media-breakpoint-down(lg) {
    margin-right: 0;
  }
}
.search_container {
  padding-left: 8vw;
  @include media-breakpoint-down(lg) {
    padding-left: 0;
  }
  #searchTextField {
    border-radius: 18px;
    border: none;
    background-color: #f5f5f5;
    padding: 0.75rem 1.5rem;
    text-align: center;
    transition: border 2s ease-in-out;
  }
  #searchTextField:focus {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.nothing_container {
  color: #1359ba;
  @include media-breakpoint-down(xl) {
    padding-left: 0;
  }
}
.all_loc_container {
  text-align: center;
  button {
    width: fit-content;
  }
}
.list_container {
  ul {
    margin: 0;
    .loc_item {
      color: black;
      border: none;
      border-radius:10px;
      width:auto;
      transition:background 150ms ease-in-out;
    }
  }
}
.my_location {
  text-align: center;
  padding-left: 4px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
}
.list-btn {
  &:hover {
    background-color: black;
    color: white;
  }
}
.shade {
  content: "";
  display: block;
  position: absolute;
  width: 370px;
  height: 72px;
  background-image: linear-gradient(to top, rgb(245 245 245 / 0%), #f5f5f5);
  pointer-events: none;
}

.gm-style iframe + div {
  border: none !important;
}

.gm-style-iw + div {
  display: none;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  color: white;
  background-color: $brand-primary;
  transition-duration: 30ms;
  -webkit-transition-duration: 30ms;
  &:hover {
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.42857;
    background-color: #1f67cd;
    color: white;
    transform: scale(1.2);
    box-shadow: -1px -1px 13px 0px rgb(0 0 0 / 30%);
  }
}

.map_btn {
  background: none padding-box rgb(255, 255, 255);
  display: table-cell;
  border: 0px;
  margin: 0px;
  padding: 0px 17px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  direction: ltr;
  overflow: hidden;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  color: rgb(86, 86, 86);
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  min-width: 66px;
  margin: 10px;
  &:hover {
    background: none padding-box rgb(235, 235, 235);
    color: rgb(0, 0, 0);
  }
}

@include media-breakpoint-up(xl) {
  .move{
    left: 56% !important;
  }
}

// Overlay Style
.mso-map-overlay {
  .mso-map {
    @include media-breakpoint-up(xl) {
      border-top-right-radius:0;
      border-bottom-right-radius:0;
    }
  }
  .store-locator-sidebar-container {
    @include media-breakpoint-up(xl) {
      width: 450px;
      position:absolute;
      top:0;
      left:0;
      bottom:25px;
      padding-left:inherit;
      border-top-right-radius:0;
    }
    .store-locator-sidebar {
      background: rgba(0,0,0,.12);
      -webkit-backdrop-filter: saturate(180%) blur(8px);
      backdrop-filter: saturate(180%) blur(8px);
      .list_container {
        ul {
          .loc_item {
            &:hover {
              background:$brand-primary;
              background: linear-gradient(145deg, $brand-primary 0%, lighten($brand-primary,15%) 100%);
              color:white!important;
              cursor:pointer;
              .h5, .mso-sidebar-more {
                color:white!important;
              }
              .mso-full-details-button {
                &:hover {
                  color:white !important;
                }
              }
            }
          }
        }
        .list-group-item.active {
          background:$brand-primary!important;
          background: linear-gradient(145deg, $brand-primary 0%, lighten($brand-primary,15%) 100%)!important;
          color:white!important;
          .h5, .mso-sidebar-more, a {
            color:white!important;
          }
          .mso-full-details-button {
            color:$brand-primary!important;
          }
        }
      }
    }
  }
}

// Separate Style
.mso-map-separate {
  .fullscreen {
    .mso-sidebar-separate {
      padding-left:0!important;
      padding-right:0!important;
    }
    .mso-map {
      border-radius:0!important;
    }
    .store-locator-sidebar-container {
      .store-locator-sidebar {
        border-radius:0!important;
      }
    }
  }
  .store-locator-sidebar-container {
    .store-locator-sidebar {
      .list_container {
        ul {
          .loc_item {
            &:hover {
              background-color: $dark;
              background: linear-gradient(145deg, $dark 0%, lighten($dark,15%) 100%);
              color:white!important;
              cursor:pointer;
              .h5, .mso-sidebar-more {
                color:white!important;
              }
              .mso-full-details-button {
                &:hover {
                  color:white !important;
                }
              }
            }
          }
        }
        .list-group-item.active {
          background-color: $dark!important;
          background: linear-gradient(145deg, $dark 0%, lighten($dark,15%) 100%)!important;
          color:white!important;
          .h5, .mso-sidebar-more, a {
            color:white!important;
          }
          .mso-full-details-button {
            color:$dark!important;
          }
        }
      }
    }
  }
}