.header-centered {
	@include media-breakpoint-down(xl) {
		padding-right:0;
	}
	.navbar-nav {
		li {
			@include media-breakpoint-up(xl) {
				display:flex;
				align-items:center;
			}
		}
	}
}