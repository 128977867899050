.rpb-photo-gallery {
  .gallery-lightbox {
    .photo-gallery-photo {
      &:hover {
        cursor:pointer;
      }
      img {
        height:auto;
      }
    }
  }
}
