// Container Fluid Adjustments
.container-fluid {
  padding-left: 25px;
  padding-right: 25px;
  @include media-breakpoint-up(md) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 75px;
    padding-right: 75px;
  }
  @include media-breakpoint-up(xxxl) {
    padding-left: 150px;
    padding-right: 150px;
  }
  @media(screen and min-width:2400px) {
    padding-left:18%;
    padding-right:18%;
  }
  @media(screen and min-width:3000px) {
    padding-left:20%;
    padding-right:20%;
  }
}